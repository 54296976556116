body {
  text-align: center;
  color: white;
  background-color: black;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

@font-face {
  font-family: "arista_pro_triallight";
  src: url("./utils/webfontkit/arista-pro-light-trial-webfont.woff2")
      format("woff2"),
    url("./utils/webfontkit/arista-pro-light-trial-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

::-webkit-scrollbar-track {
  background-color: black;
}

::-webkit-scrollbar {
  width: 3px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  border-radius: 50px;
  background-color: rgb(170, 139, 130);
}

/*~~~~~~~ App ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/

.app {
  display: grid;
  grid-template-columns: repeat(3, 100vw);
  grid-template-rows: 100vh;
  grid-template-areas: "left center right";
  transition: 1s;
  overflow: hidden;
  margin: 0;
}
.open-chat {
  margin-left: -100vw;
}
.open-left-menu {
  margin-left: 0vw;
}
.open-right-menu {
  margin-left: -200vw;
}

@media (min-width: 768px) {
  .app {
    grid-template-columns: 25vw 100vw 25vw;
  }
  .open-chat {
    margin-left: -25vw;
  }
  .open-right-menu {
    margin-left: -50vw;
  }
}

/*~~~~~~~ Login Screen ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/

.login-screen {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.app-title {
  margin: -50px 0 100px 0;
  display: inline-block;
  letter-spacing: 2px;
  font-weight: normal;
  color: rgb(255, 189, 158);
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  font-size: 25px;
}

.login-screen input:focus {
  outline: none;
}
.login-screen input::placeholder {
  color: rgb(170, 139, 130);
}

.later-input {
  position: relative;
  flex-direction: column;
}

.go-back {
  color: white;
  background-color: transparent;
  border: none;
  display: block;
  cursor: pointer;
  pointer-events: auto;
  width: 40px;
  height: 40px;
  text-align: center;
  margin: auto;
  padding: auto;
}

.show-login-input {
  display: block;
}

.hide-login-input {
  display: none;
}

/*~~~~~~ Header ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/

header {
  position: fixed;
  top: 0;
  height: 80px;
  width: 100vw;
  z-index: 3;
}

nav {
  height: 100%;
  width: 97%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
}
nav::-webkit-scrollbar {
  height: 0px;
  background: transparent; /* Chrome/Safari/Webkit */
}

nav a:visited {
  color: white;
  text-decoration: none;
}

.nav-list {
  position: relative;
  height: 100%;
  width: 100%;
  list-style: none;
  margin-block-start: 0em;
  margin-block-end: 0em;
  padding-inline-start: 0px;
  display: grid;
  grid-template-columns: 50px 2fr 130px 1fr 1fr 50px;
  grid-template-rows: 1fr;
  grid-template-areas: "left-button recipient center-button username-1 username-2 right-button";
  z-index: 3;
  transition: 1s;
}
@media (max-width: 768px) {
  .nav-list {
    grid-template-columns: 50px 2fr 110px 1fr 1fr 50px;
  }
}
.nav-list li {
  display: flex;
  flex-direction: row;
  align-items: center;
  transition: 0.5s;
  transition-timing-function: cubic-bezier(0.33, 0.15, 0.5, 1.08);
}
.left-button {
  grid-area: left-button;
}
.recipient-name {
  grid-area: recipient;
}
.center-button {
  grid-area: center-button;
}
.username-1 {
  grid-area: username-1;
}
.username-2 {
  grid-area: username-2;
}
.right-button {
  grid-area: right-button;
}

.live-username {
  padding-bottom: 3px;
  color: rgb(255, 189, 158);
  transition: 0.3s;
}

.username {
  padding-bottom: 3px;
  color: white;
  transition: 0.3s;
}

.round-button {
  font-size: 1em;
  width: 25px;
  height: 25px;
  margin: 10px;
  padding: 0px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  pointer-events: auto;
  transition: 0.5s;
}

.round-button:focus {
  outline: none;
}

.plain-button {
  color: rgb(170, 139, 130);
  border: solid 2px rgb(170, 139, 130);
  background-color: transparent;
}
.plain-button:hover {
  color: rgb(219, 183, 173);
  border: solid 2px rgb(219, 183, 173);
}

.online-button {
  font-weight: bold;
  color: transparent;
  background-color: rgb(170, 139, 130);
  border: solid 3px rgb(170, 139, 130);
}
.online-button:hover {
  color: black;
}
.live-button {
  color: rgb(255, 189, 158);
  background-color: rgb(255, 189, 158);
  border: solid 3px rgb(255, 189, 158);
}
.live-button:hover {
  color: black;
}
.offline-button {
  color: transparent;
  border: solid 3px rgb(170, 139, 130);
  background-color: transparent;
}
.offline-button:hover {
  color: rgb(170, 139, 130);
}

/*~~~~~ Nav Animation  ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/

.circle {
  font-size: 1em;
  width: 22px;
  height: 22px;
  /* margin: 10px; */
  padding: 0px;

  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  pointer-events: none;
  transition: 1s;
}

.animated-nav {
  width: 90px;
  height: 50px;
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  opacity: 1;
  pointer-events: none;
  z-index: 0;
  transition: 0.3s;
}
@media (max-width: 768px) {
  .animated-nav {
    width: 80px;
  }
}
.hidden-nav {
  width: 100vw;
  opacity: 0;
}

.close-connection {
  width: 130px;
  height: 50px;
  color: transparent;
  background-color: transparent;
  border: none;
  border-radius: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  overflow: visible;
  cursor: pointer;
}
.close-connection:focus {
  outline: none;
}
.close-connection:hover {
  color: rgb(219, 183, 173);
}

/*~~~~~ Menus  ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/

.menu-title {
  height: 80px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  background-color: rgb(36, 26, 23);
  position: relative;
  z-index: 4;
  /* background-color: rgb(1, 59, 44); */
}

.menu-title h3 {
  font-weight: normal;
  margin-block-start: 0em;
  margin-block-end: 0em;
  margin: 0 auto;
  z-index: 2;
}

.menu-title button {
  position: relative;
  margin: 10px;
  border: none;
  border-radius: 50px;
  color: rgb(170, 139, 130);
  background-color: transparent;
  cursor: pointer;
  pointer-events: auto;
  transition: 0.5s;
  z-index: 4;
}
.menu-title button:hover {
  color: rgb(219, 183, 173);
}

/*~~~~ Connections Menu ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
.conns-menu {
  grid-area: left;
  width: 100%;
  height: 100%;
  overflow: hidden;
  transition: 0.4s;
  transition-timing-function: cubic-bezier(0.65, 0.11, 0.78, 1.05);
}

.connections {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.connections ul {
  list-style: none;
  padding-inline-start: 0px;
}

.contact {
  margin-bottom: 20px;
  padding: 10px;
  border-radius: 0px 50px 50px 0px;
  color: white;
  cursor: pointer;
  transition: 0.3s;
}
.contact:hover {
  background-color: rgb(170, 139, 130);
}

.online {
  color: white;
  border: 2px solid rgb(170, 139, 130);
}

.live {
  font-weight: bold;
  color: black;
  background-color: rgb(255, 189, 158);
}

@media (hover: hover) {
  .online:hover {
    color: white;
    background-color: rgb(170, 139, 130);
  }
  .live:hover {
    color: white;
    background-color: rgb(2, 92, 68);
  }
}

.plus-button {
  border: 2px solid black;
}
.plus-button:hover {
  border: 2px solid rgb(170, 139, 130);
  background-color: rgb(29, 29, 29);
}

.add-contact {
  color: white;
  padding: 10px;
  border-radius: 0px 50px 50px 0px;
  border: 2px solid rgb(170, 139, 130);
  background-color: rgb(29, 29, 29);
  transition: 0.3s;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.add-contact button {
  position: relative;
  height: 24px;
  width: fit-content;
  color: rgb(219, 183, 173);
  border: none;
  background-color: transparent;
  outline: none;
  opacity: 0.9;
  cursor: pointer;
  z-index: 2;
  transition: 0.4s;
}

.add-contact-input {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  font-size: 1em;
  width: 70%;
  color: rgb(219, 183, 173);
  background-color: transparent;
  padding: 0px 15px;
  border-radius: 50px;
  border: none;
  opacity: 1;
  z-index: 4;
  transition: 0.35s ease-in-out;
}

.add-contact input:focus {
  outline: none;
}
.add-contact input::placeholder {
  color: rgb(219, 183, 173);
}

/*~~ Options menu ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/

.options-menu {
  grid-area: right;
  width: 100%;
  height: 100%;
  transition: 0.4s;
}

.options-login-info {
  width: 100%;
  font-size: 1rem;
  text-align: left;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  transition: 0.3s;
}

.option-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: fit-content;
  width: 100%;
  margin-bottom: 15px;
  background-color: rgb(36, 26, 23);
  border-radius: 5px;
  transition: 0.4s;
}

.option-label {
  width: 100%;
  height: fit-content;
  padding: 2ch 0;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  font-size: 1rem;
  transition: 0.3s;
  cursor: pointer;
}
@media (hover: hover) {
  .option-label:hover {
    background-color: rgba(255, 255, 255, 0.03);
  }
}

.option-content {
  max-height: 500px;
  opacity: 1;
  transition: 0.4s;
}
.option-content > * {
  margin: 20px 40px;
}

.hidden-content {
  max-height: 0px;
  opacity: 0;
  overflow: hidden;
  transition: 0.4s;
}
.hidden-content > * {
  margin: 20px 40px;
}

.success-check {
  display: inline-block;
}

.logout-label {
  display: inline-block;
  width: fit-content;
  margin-left: auto;
  padding: 5px 25px;
  font-size: 1em;
  background-color: rgb(36, 26, 23);
  border-radius: 50px;
  transition: 0.4s;
}
.logout-label:hover {
  background-color: rgba(255, 255, 255, 0.2);
  cursor: pointer;
}

.delete-user:hover {
  color: orange;
}

.option-form {
  display: flex;
  flex-direction: column;
}

.option-form * {
  color: white;
  padding: 5px;
  margin: 2ch;
  border: 2px solid rgb(36, 26, 23);
  border-radius: 5px;
  background-color: black;
}

.option-input:focus {
  outline: none;
}

.option-form button {
  padding: 5px;
  margin: 2ch;
  border: 2px solid rgb(36, 26, 23);
  border-radius: 5px;
  font-size: 1rem;
  color: rgba(255, 255, 255, 0.8);
  background-color: rgb(51, 51, 51);
  cursor: pointer;
}
.option-form button:hover {
  background-color: rgb(54, 54, 54);
}

.option-info {
  max-width: 500px;
}

.dark-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: black;
  opacity: 0.8;
  transition: 0.3s;
}

.hide-dark-background {
  opacity: 0;
  pointer-events: none;
}

.register-form {
  display: flex;
  flex-direction: column;
  width: 270px;
  margin: auto;
}

.login-form {
  display: flex;
  flex-direction: column;
  width: 270px;
  margin: auto;
}

.user-selection {
  margin-top: 20vh;
}

.user-button {
  display: inline-block;
  margin: 20px auto;
}

.delete-user-button {
  display: inline-block;
  margin: 20px auto;
}

/*~~~~~~~ Chat Log ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/

.chat-window {
  grid-area: center;
  width: 100%;
  height: 100%;
  transition: 0.4s;
}

.dark-gradient {
  position: absolute;
  width: 100%;
  height: 40vh;
  background: linear-gradient(180deg, #000000 0%, rgba(196, 196, 196, 0) 100%);
  z-index: 1;
  pointer-events: none;
}

.chat-log {
  position: relative;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: flex-start;
  transition: 0.3s;
  z-index: 0;
}

.message-list {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-start;
  overflow-wrap: break-word;
  list-style: none;
  padding-inline-start: 0px;
  padding-inline-end: 0px;
  padding-block-start: 400px;
  padding-block-end: 100px;
  transition: 0.4s;
}

.my-message-line {
  text-align: right;
  white-space: pre;
}

.my-message {
  display: inline-block;
  white-space: normal;
  text-align: left;
  min-height: 21px;
  max-width: 40%;
  margin-bottom: 5px;
  padding: 10px 40px;
  padding-right: 26vw;
  font-weight: 00;
  color: white;
  border-bottom: 1px solid rgb(255, 189, 158);
  /* border-right: 5px solid rgb(255, 189, 158); */
  border-radius: 0px 0px 0px 0px;
}

.their-message {
  text-align: left;
  color: white;
  width: fit-content;
  max-width: 40%;
  min-height: 21px;
  margin-bottom: 5px;
  padding: 10px 40px;
  padding-left: 26vw;
  border-bottom: 1px solid rgb(170, 139, 130);
  /* border-left: 5px solid rgb(170, 139, 130); */
  background-color: black;
  border-radius: 0px 0px 0px 0px;
}

@media (max-width: 768px) {
  .message-list {
    font-size: 1.2em;
  }
  .my-message {
    max-width: 70%;
    padding: 10px 20px;
  }
  .their-message {
    max-width: 70%;
    padding: 10px 20px;
  }
}

.chat-bottom {
  height: 100px;
  width: 100%;
}

.their-live-text {
  border: 1px solid rgb(255, 189, 158);
  background-color: rgb(255, 189, 158);
  color: black;
  font-size: 1.5em;
  font-weight: 500;
  border-radius: 0px 50px 50px 0px;
  width: fit-content;
  max-width: 40vw;
  min-height: 21px;
  margin-top: 20px;
  padding: 10px 40px 10px 25vw;
}

/*~~~~~~~ Message Input ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~  */

.message-form {
  position: fixed;
  bottom: 0;
  width: 100vw;
  height: 116px;
  z-index: 3;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.message-input {
  width: 50%;
  font-size: 1.5em;
  text-align: center;
  padding: 0px;
  font-weight: 400;
  color: white;
  border: none;
  border: 1px solid rgb(255, 189, 158);
  background-color: black;
  border-radius: 50px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  pointer-events: auto;
  transition: 0.4s;
}
@media (max-width: 700px) {
  .message-input {
    width: 90%;
    border: 2px solid rgb(255, 189, 158);
  }
}

.live-input {
  border: 3px solid rgb(255, 189, 158);
  background-color: black;
  color: rgb(255, 189, 158);
  font-weight: 500;
  border-radius: 50px;
}

.message-input:focus {
  outline: none;
  padding: 10px 5px;
}
